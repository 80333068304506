import React from "react";
import "./style/Modal.css";
import summaryImage from "../static/image.png";
import CloseIcon from "../static/icons/CloseIncon";
interface Props {
  className: string;
  goToExtension(): void;
  onClose(): void;
}
function Modal({ className, goToExtension, onClose }: Props) {
  return (
    <div className={`modal ${className}`}>
      <div className="container">
        <CloseIcon onClick={onClose} />
        <p className="title">Get the extension now!</p>
        <p>
          Do you want a more powerful and easy to use tool to summarise the
          content of any web page? Download our free browser extension now.
        </p>
        <img src={summaryImage} alt="summary" className="image" />
        <div className="get-button" onClick={goToExtension}>
          <p>Get extension free!</p>
        </div>
      </div>
    </div>
  );
}

export default Modal;
