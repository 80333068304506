export const fetchData = async (
  route: string,
  options: { method: string; body?: any }
): Promise<any> => {
  try {
    const response = await fetch(
      `https://summary-ai-099ea160be07.herokuapp.com${route}`,
      {
        method: options.method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(options.body),
      }
    );
    const result = await response.json();
    if (result.error) {
      return result;
    }
    return result.data;
  } catch (e) {
    console.error(e);
  }
};
