import React, { useEffect, useState } from "react";
import "../App.css";

interface Props {
  text: string;
  error?: boolean;
}
function Typer({ text, error }: Props) {
  const [idx, setidx] = useState(0);
  useEffect(() => {
    const timer = window.setInterval(() => setidx((v) => v + 1), 15);
    return () => window.clearInterval(timer);
  });

  return error ? (
    <p className="result-error-text">{text.substr(0, idx)}</p>
  ) : (
    <p className="result-text">{text.substr(0, idx)}</p>
  );
}

export default Typer;
