import React, { FormEvent, useState } from "react";
import { fetchData } from "./utils/fetchData";
import Modal from "./components/Modal";
import Typer from "./components/Typer";
import LogoIcon from "./static/icons/LogoIcon";
import "./App.css";

function App() {
  const [siteUrl, setSiteUrl] = useState("");
  const [summariseResult, setSummariseResult] = useState("");
  const [summariseError, setSummariseError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalCounter, setShowModalCounter] = useState(0);
  const summarize = async (e: FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    const result = await fetchData("/generate", {
      method: "POST",
      body: { url: siteUrl },
    });
    if (result.error) {
      setSummariseResult("");
      setSummariseError(
        "Something went wrong. Please make sure to use the url in the following format: https://www.website.domain"
      );
    } else {
      setSummariseError("");
      setSummariseResult(result);
    }
    setIsLoading(false);

    if (showModalCounter === 0 && !result.error) {
      setTimeout(() => {
        setShowModal(true);
        setShowModalCounter(1);
      }, 15000);
    }
  };

  const goToExtension = () => {
    window.open(
      "https://chromewebstore.google.com/detail/summaryai/mhnldopecokgbhjglepggmhpgjfgalgm",
      "_blank"
    );
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="App">
      <div className="header">
        <LogoIcon />
        <div className="get-button" onClick={goToExtension}>
          <p>Get extension free!</p>
        </div>
      </div>
      <div className="content">
        <p className="title">WHAT IS THIS PAGE ABOUT?</p>
        <p className="subtitle">
          Are you not sure what a website is about? Copy the link and we will
          summarise it for you.
        </p>
        <form className="form-container" onSubmit={(e) => summarize(e)}>
          <input
            type="text"
            placeholder="e.g: https://www.languify.ai"
            className="input-site"
            value={siteUrl}
            onChange={(e) => setSiteUrl(e.target.value)}
          />
          <div
            onClick={summarize}
            className={
              isLoading || siteUrl === ""
                ? "loading get-button summarise"
                : "get-button summarise"
            }
          >
            {isLoading ? <div className="loader"></div> : <p>Summarise</p>}
          </div>
        </form>
        {summariseResult && <Typer text={summariseResult} />}
        {summariseError && <Typer text={summariseError} error />}
      </div>
      <Modal
        className={showModal ? "visible" : ""}
        goToExtension={goToExtension}
        onClose={closeModal}
      />
    </div>
  );
}

export default App;
